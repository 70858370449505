<template>
  <div v-if="isAuthenticated">
    <KTHeaderMobile v-if="isMobile"></KTHeaderMobile>
    <KTHeader></KTHeader>

    <div class="d-flex flex-column flex-root">
      <Loader v-bind:logo="loaderLogo"></Loader>

      <div class="d-flex flex-row flex-column-fluid page">
        <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid">
          <!-- begin:: Content -->
          <div
            id="kt_content"
            class="content d-flex flex-column flex-column-fluid"
          >
            <!-- begin:: Content Head -->
            <KTSubheader
              v-if="subheaderDisplay && displaySubheaderOnDashboard"
              v-bind:breadcrumbs="breadcrumbs"
              v-bind:title="pageTitle"
            />
            <!-- end:: Content Head -->

            <!-- begin:: Content Body -->
            <div class="d-flex flex-column-fluid">
              <div
                :class="{
                  'container-fluid': contentFluid,
                  container: !contentFluid
                }"
              >
                <div class="d-lg-flex flex-row-fluid">
                  <div class="content-wrapper flex-row-fluid">
                    <router-view
                      v-if="!$route.path.includes('/admin/setting')"
                      :key="$route.path"
                    />
                    <router-view
                      v-if="$route.path.includes('/admin/setting')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <KTFooter></KTFooter>
        </div>
      </div>

      <ChatButton></ChatButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTHeader from "@/view/layout/admin/Header";
import KTHeaderMobile from "@/view/layout/admin/HeaderMobile.vue";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import Loader from "@/view/content/Loader.vue";
import ChatButton from "@/components/ChatButton.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import { isMobile } from "@/lib/detectDevice";

export default {
  name: "LayoutAdmin",
  components: {
    KTHeader,
    KTHeaderMobile,
    KTSubheader,
    KTFooter,
    Loader,
    ChatButton
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 200);
  },
  data() {
    return {
      isMobile: isMobile()
    };
  },
  methods: {
    footerLayout(type) {
      return this.layoutConfig("footer.layout") === type;
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    /**
     * Set the subheader display on dashboard page
     * @returns {boolean}
     */
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    }
  }
};
</script>
